import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Link from "../../components/link";
import WhatWeDoHero from "../../components/what-we-do-hero";
import HowCanWeHelpRight from "../../components/how-can-we-help-right";
import HowCanWeHelpLeft from "../../components/how-can-we-help-left";
import PhotoLeft from "../../components/photo-left";
import CtaBanner from "../../components/cta-banner";
import BeeBanner from "../../components/bee-banner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { GoPrimitiveDot } from "react-icons/go";
import SVGPatternedSidebar from "../../components/SVG/patterned-sidebar";
import SVGLcdBgPoly1 from "../../components/SVG/lcd-bg-poly-1";
import SVGLcdBgPoly2 from "../../components/SVG/lcd-bg-poly-2";
import SVGLcdBgPoly3 from "../../components/SVG/lcd-bg-poly-3";
import SVGLcdBgPoly4 from "../../components/SVG/lcd-bg-poly-4";
import SVGFBLcd from "../../components/SVG/flying-bee-lcd";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Container } from "react-bootstrap";
import TextOnly from "../../components/text-only";
import PhotoRight from "../../components/photo-right";
import SVGFBHp1 from "../../components/SVG/flying-bee-hp-1";
import SVGFBHp2 from "../../components/SVG/flying-bee-hp-2";

const HRWorthing = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Worthing-Pier" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			coreValuesImg: wpMediaItem(title: { eq: "RFG-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			trainingImg: wpMediaItem(title: { eq: "Tailor-Made-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			EEImg: wpMediaItem(title: { eq: "Employee-Engagement-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ourTeamImg: wpMediaItem(title: { eq: "Our-Team-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			areYouTiredImg: wpMediaItem(title: { eq: "Employee-Engagement-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			backgroundImg: wpMediaItem(title: { eq: "LCD-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ErImg: wpMediaItem(title: { eq: "Personal-Service-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			rrsImg: wpMediaItem(title: { eq: "Proactive-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			employeeAppraisalsImg: wpMediaItem(title: { eq: "Blog-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const isDarken = true;
	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const ErImg = data.ErImg?.localFile.childImageSharp.gatsbyImageData;
	const trainingImg =
		data.trainingImg?.localFile.childImageSharp.gatsbyImageData;
	const EEImg = data.EEImg?.localFile.childImageSharp.gatsbyImageData;
	const coreValuesImg =
		data.coreValuesImg?.localFile.childImageSharp.gatsbyImageData;
	const employeeAppraisalsImg =
		data.employeeAppraisalsImg?.localFile.childImageSharp.gatsbyImageData;
	const ourTeamImg = data.ourTeamImg?.localFile.childImageSharp.gatsbyImageData;
	const areYouTiredImg =
		data.areYouTiredImg?.localFile.childImageSharp.gatsbyImageData;

	const backgroundImg =
		data.backgroundImg?.localFile.childImageSharp.gatsbyImageData;

	const rrsImg = data.rrsImg?.localFile.childImageSharp.gatsbyImageData;

	const StyledBackground = styled(BgImage)`
		&::before,
		&::after {
			filter: brightness(
				${({ isDarken }) => {
					return isDarken ? "40%" : "100%";
				}}
			);
		}
	`;
	const pluginImage = getImage(backgroundImg);

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": "https://www.hivehrsolutions.co.uk/locations/hr-worthing/#worthing",
		image: `https://www.hivehrsolutions.co.uk`,
		mpn: "https://www.hivehrsolutions.co.uk/locations/hr-worthing",
		sku: "https://www.hivehrsolutions.co.uk/locations/hr-worthing",
		description:
			"Tailor-made HR consultancy in Worthing to save you time and improve your employee engagement. Contact us to discuss your outsourced HR support in Worthing.",
		logo: `https://www.hivehrsolutions.co.uk`,
		name: "Hive HR Solutions",
		url: "https://www.hivehrsolutions.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/HiveHR-Logo-Vertical-2021-GLOSSY-RGB.png`,
			image: `https://www.hivehrsolutions.co.uk`,
			name: "Hive HR Solutions",
			url: "https://www.hivehrsolutions.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `75`,
			ratingValue: "4.9",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.hivehrsolutions.co.uk/reviews",
			datePublished: "2022-08-30T17:58:29+00:00",
			reviewBody:
				"My experience in working with Hive HR Solutions was very much a positive one",
			author: {
				"@type": "Organization",
				name: "Voluntary Services Lewisham",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Hive HR Solutions",
				sameAs: "https://www.hivehrsolutions.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Locations",
				item: {
					url: `${siteUrl}/locations`,
					id: `${siteUrl}/locations`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Worthing",
				item: {
					url: `${siteUrl}/locations/hr-worthing`,
					id: `${siteUrl}/locations/hr-worthing`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Worthing HR Consultant | HR Support Worthing | Hive HR"
					description="Tailor-made HR consultancy in Worthing to save you time and improve your employee engagement. Contact us to discuss your outsourced HR support in Worthing."
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/locations/hr-worthing`,
						title:
							"HR Consultant in Worthing | Worthing HR Support | Hive HR Solutions",
						description:
							"Tailor-made HR consultancy to save you time and improve employee engagement",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className=" pt-md-4 pt-lg-0">
					<WhatWeDoHero
						hideSHeading
						pos="30%"
						title="Looking for an HR consultant in Worthing?"
						img={
							<div>
								<Row className="g-0 d-lg-block d-none ">
									<Col
										style={{ overflow: "hidden" }}
										className="g-0  pb-0   position-relative"
									>
										<GatsbyImage
											style={{ width: "84%" }}
											className=" tImage  mb-4"
											alt="HR consultant in Worthing providing HR support"
											image={heroImg}
										/>
										<SVGPatternedSidebar
											style={{ width: "20%", right: "-1rem" }}
											className="position-absolute bg-primary tImage top-0  "
										/>
									</Col>
								</Row>
								<GatsbyImage
									className="w-100 d-lg-none mb-4"
									alt="Outsourced HR consultant in Worthing office"
									image={heroImg}
								/>
							</div>
						}
					/>
				</section>
				<section className="pb-lg-10 pt-xl-7 position-relative">
					<SVGLcdBgPoly1
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--10 d-none d-lg-block "
					/>
					<HowCanWeHelpLeft
						height="25rem"
						width="33rem"
						img={EEImg}
						imgAlt="HR support in Worthing"
						heading="If you’re running a business in the Worthing area we know how important it is for you to make sure your business is on the right side of the employment law."
						text={
							<span>
								<p>
									Our expert HR consultants can help you stay compliant by
									providing the following services.
								</p>
							</span>
						}
						list={
							<ul className="help-list ps-0 ps-lg-2">
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
									</div>
									<p className="text-start">
										Providing tailor-made HR consultancy to save you time and
										improve employee engagement
									</p>
								</li>

								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>
									<p className="text-start">
										Added value with a bespoke HR consultancy support service
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
									</div>
									<p className="text-start">
										Efficient use of your time through regular HR services
										meetings
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
									</div>
									<p className="text-start">
										Practical solutions from a Worthing HR consultancy company
										that really cares
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
									</div>
									<p className="text-start">
										Proactive rather than reactive HR support
									</p>
								</li>
							</ul>
						}
					/>
				</section>
				<section className="pb-lg-8 pt-lg-10 position-relative">
					<SVGLcdBgPoly4
						style={{ zIndex: -2 }}
						className="position-absolute start-0 top--40 d-none d-lg-block "
					/>
					<SVGFBHp2
						style={{ zIndex: -2 }}
						className="position-absolute end-0 bottom--40 d-none d-xl-block"
					/>
					<HowCanWeHelpRight
						listHeading="Services we offer:"
						height="33rem"
						width="30rem"
						img={ourTeamImg}
						imgAlt={data.ourTeamImg?.altText}
						heading="How we support businesses with our HR consultancy services"
						text={
							<span>
								<p>
									We are a HR consultancy company that provides tailored answers
									to all your HR support problems.
								</p>
								<h3 className="fs-4">
									Do you have a small but growing team and just need some HR
									advice?
								</h3>
								<p>
									Whether you need us to draft an employment contract or
									employee handbook we can help by offering a one-off ad hoc
									service, a block of 10 hours or a monthly package that is
									tailored to suit your business’s specific needs.
								</p>
							</span>
						}
						list={
							<ul className="help-list ps-0 ">
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>
									<p className="text-start">Employee relations</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>
									<p className="text-start">Disciplinaries and grievances</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>
									<p className="text-start">Recruitment processes</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>
									<p className="text-start">Employment contracts</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>

									<p className="text-start">Employee handbooks</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>
									<p className="text-start">Effective management training</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>

									<p className="text-start">
										Employee appraisals and performance management
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-0 me-2 fs-4" />
									</div>
									<p className="text-start">BreatheHR software</p>
								</li>
							</ul>
						}
					/>
				</section>

				<section className="pt-lg-8 pt-5 pb-lg-0 pb-5 mt-lg-5 position-relative">
					<StyledBackground
						isDarken={isDarken}
						key={isDarken ? `dark` : `light`}
						className="py-5"
						image={pluginImage}
					>
						<Container>
							<Row>
								<Col className="text-white my-lg-10 ">
									<h2>
										Are you looking for tailor-made solution from a
										Worthing-based HR consultancy firm?
									</h2>
									<p>
										Do you run a business in Worthing and want to work with an
										HR consultancy company that gets a real buzz out of helping
										growing companies find the right staff and retain them?
									</p>
									<p>
										{" "}
										Would you like to improve your employee engagement and run a
										team of happy, fulfilled staff who can’t wait to come to
										work every day?
									</p>
									<p>
										Just imagine the productivity boost happy employees could
										have on your business!
									</p>
									<p>
										{" "}
										At Hive HR Solutions our goal is to save you time and
										improve employee engagement.
									</p>
									<p>
										{" "}
										Our expert HR consultants are all based in West Sussex and
										are here to support by providing practical HR solutions to
										all your staffing issues, from creating bespoke performance
										management documentation to employment contracts, we can
										help you nurture, motivate and train your team leaving you
										free to focus on running your business.
									</p>
								</Col>
							</Row>
						</Container>
					</StyledBackground>
				</section>
				<section className=" position-relative pt-xl-5 ">
					<TextOnly
						heading="Added value with a bespoke HR consultant Worthing support service"
						text={
							<span>
								<p>
									Do you want to add value to your company with personalised HR
									packages that suit your budget and requirements?
								</p>
							</span>
						}
					/>
				</section>
				<section>
					<PhotoLeft
						idLeft="Are-Left"
						idRight="Are-Right"
						height="25rem"
						width="38rem"
						img={employeeAppraisalsImg}
						imgAlt={data.employeeAppraisalsImg?.altText}
						heading="Are you tired of trying to do everything yourself?"
						text={
							<span>
								<p>
									Would you like support from an HR company based in Worthing,
									West Sussex that works with clients across the south-east of
									England and can identify any HR problems before they happen
									and avoid unwanted disciplinary procedures and grievances?
								</p>
							</span>
						}
					/>
				</section>
				<section className=" position-relative pt-xl-5 position-relative">
					<SVGLcdBgPoly2
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--70  d-none d-lg-block"
					/>
					<TextOnly
						heading="Professional HR services with flexible plans that suit your needs and budget"
						text={
							<span>
								<p>
									At Hive HR Solutions we understand that in order to have
									satisfied clients you need to ensure your employees are happy,
									which is why we provide a comprehensive suite of human
									resources management services for small and medium-sized
									businesses.
								</p>
							</span>
						}
					/>
				</section>
				<section className=" pt-xl-6 pt-4 pt-lg-0 pb-xl-5">
					<PhotoRight
						height="25rem"
						width="38rem"
						img={rrsImg}
						imgAlt={data.rrsImg?.altText}
						heading="Maximise your time with regular HR support meetings"
						text={
							<span>
								<p>
									Let our team help you manage your employees by scheduling HR
									support meetings with you so any current or future issues can
									be addressed and nipped in the bud.
								</p>
								<p>
									As a regular client, you can benefit from weekly or
									fortnightly meetings - or at a frequency that suits you – with
									our expert Worthing HR consultants to discuss your HR issues.
								</p>
							</span>
						}
					/>
				</section>
				<section className="pb-lg-10 position-relative">
					<SVGFBLcd
						style={{ zIndex: -2, top: "-35%", left: "-5%" }}
						className="position-absolute    d-none d-xl-block "
					/>
					<HowCanWeHelpLeft
						height="25rem"
						width="33rem"
						img={coreValuesImg}
						imgAlt={data.coreValuesImg?.altText}
						heading="Do you need help with employment law compliance?"
						text={
							<span>
								<p>
									Our team will invest the time it takes to really get to know
									your company and your specific HR services needs so they can
									give you the best support and advice to ensure you are always
									compliant with employment law and have happy, productive
									staff.
								</p>
							</span>
						}
						list={
							<span>
								<p>
									We will organise the meeting, from writing the agenda to
									taking the minutes. All you have to do is show up, we’ll take
									care of the rest.
								</p>
								<p>
									With our support you can focus on growing your business, safe
									in the knowledge that your HR requirements are taken care of
									by our dedicated team of local HR consultants.
								</p>
								<p>
									We can assist you throughout your recruitment process from
									writing job specs to screening and interviewing candidates,
									drafting employment contracts and once you have your dream
									team in place we can help you ensure you have an excellent
									employee retention rate.
								</p>
							</span>
						}
					/>
				</section>

				<section className="pt-lg-10 mt-lg-5">
					<CtaBanner
						headline="Ready to speak to us?"
						btnTxt="Get in touch"
						btnLink="/contact-us"
						gradient={true}
					/>
				</section>
				<section className=" position-relative pt-5 ">
					<TextOnly
						heading="Practical solutions from a Worthing HR company that really cares"
						text={
							<span>
								<p>Your business is as important to us as it is to you.</p>
								<p>
									That’s why you’ll always hear the friendly voice of one of our
									team members at the end of the phone who knows your business
									inside out, the direction you want to take it in and who is
									committed to helping you achieve your HR goals.
								</p>
								<p>
									Your peace of mind matters to us, which is why our Worthing
									team of experienced, professional HR consultants are here for
									you and are ready to take the sting out of your HR issues
									wherever you are located in Worthing.
								</p>
							</span>
						}
					/>
				</section>
				<section className="position-relative">
					<SVGLcdBgPoly1
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--10 d-none d-lg-block "
					/>
					<PhotoLeft
						idLeft="Proactive-Left"
						idRight="Proactive-Right"
						height="25rem"
						width="38rem"
						img={ErImg}
						imgAlt={data.ErImg?.altText}
						heading="Proactive rather than reactive HR support"
						text={
							<span>
								<p>
									At Hive HR Solutions in Worthing, West Sussex, we’re
									passionate about taking a proactive approach to solving your
									company’s human resources problems.
								</p>
								<p>
									Our goal is to help your business save money, stress and time
									by anticipating any potential HR problems that are on the
									horizon, identifying any HR employment law compliance gaps and
									resolving them by drawing up an action plan that we can tweak
									over time to suit your business.
								</p>
								<p>
									If you’re running a business in Worthing and want to avoid any
									future HR issues, costs and possibly even litigation, invest
									in your company’s HR now so you can avoid paying a heftier
									price later.
								</p>
							</span>
						}
					/>
				</section>
				<section className="pt-lg-5">
					<CtaBanner
						headline="You’ve got this far on your own, now let us help you take your business to the next level."
						btnTxt="Get in touch"
						btnLink="/contact-us"
						gradient={true}
					/>
				</section>
				<section className=" position-relative pt-5  ">
					<TextOnly
						heading="How HR advice can save you time and money"
						text={
							<span>
								<p>
									Human resources are the backbone of any company and the reason
									why some thrive while others fail. Can you afford to neglect
									this important aspect of your business as you look to grow?
								</p>
								<p>
									From defining your company’s value to creating you own unique
									culture our expert HR consultants in Worthing and Brighton can
									help you elevate your business through tailor-made HR advice.
								</p>
								<p>
									Let us support you and your staff in all your HR matters.
									Through effective management you can avoid costly and
									reputational-damaging employee disputes and grievances.
								</p>
								<p>
									Our Worthing HR consultants can help you to achieve this by
									managing disciplinaries and grievances so you don’t have to.
								</p>
							</span>
						}
					/>
				</section>
				<section className="bg-banner">
					<Container>
						<Row>
							<Col className="py-5">
								<p className="text-center px-lg-10">
									"Human resources are the backbone of any company and the
									reason why some thrive while others fail. Can you afford to
									neglect this important aspect of your business as you look to
									grow? From defining your company’s value to creating you own
									unique culture our expert HR consultants in Worthing and
									Brighton can help you elevate your business through
									tailor-made HR advice. Let us support you and your staff in
									all your HR matters. Through effective management you can
									avoid costly and reputational-damaging employee disputes and
									grievances. Our Worthing HR consultants can help you to
									achieve this by managing disciplinaries and grievances so you
									don’t have to."
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<PhotoLeft
						idLeft="importance-Left"
						idRight="importance-Right"
						height="25rem"
						width="38rem"
						img={trainingImg}
						imgAlt={data.trainingImg?.altText}
						heading="The importance of hiring the right people for your business"
						text={
							<span>
								<p>
									Company development starts with staff development, whether
									that involves training, performance reviews but always start
									with recruitment. Hiring people that are a good fit for your
									company is key to succeeding.
								</p>
								<p>
									Your workforce is your most valuable asset, our Worthing team
									can advise you on all of your HR issues so that we can ensure
									that your employees feel valued and protected.
								</p>
							</span>
						}
					/>
				</section>
				<section className="pt-xl-10 mt-lg-7 position-relative">
					<SVGFBHp1 className="position-absolute end-0 top--40 d-none d-lg-block" />
					<BeeBanner
						headline="Talk to an HR consultant today to discuss BreatheHR software."
						btnTxt="Contact Us"
						btnLink="/contact-us"
					/>
				</section>
			</Layout>
		);
	}
};

export default HRWorthing;
